import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ext-js-introduction",
  "date": "2014-12-21",
  "title": "EXT.JS – INTRODUCTION",
  "author": "admin",
  "tags": ["development", "javascript", "ext.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Ext JS is a product of Sencha. Ext JS is the industry’s most powerful desktop application development platform with unparalleled cross-browser compatibility, advanced MVC architecture, plugin-free charting, and modern UI widgets."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`HISTORY`}</h2>
    <p>{`Ext JS is a client-side, JavaScript framework for building web applications. In early 2006, Jack Slocum (jackslocum.com) started working on a set of extension utilities for the Yahoo User Interface (YUI) library under the name "yui-ext." Eventually, Jack created an adapter layer through which any foundation library could be plugged in to provide utilities such as event handling, DOM manipulation and animated effects. This unanchored the library from YUI, and allowed the use of YUI, jQuery, prototype, or a new ext-base foundation written by Jack. This was version 1 of Ext JS. `}</p>
    <h2>{`SETUP EXTJS DEVLOPMENT ENVIRONMENT`}</h2>
    <p>{`Before starting with Sencha ExtJS 4, you need to set up a development environment for it. There are two things required for Ext JS application development, Sencha SDK and IDE to write a code.`}</p>
    <h2>{`SENCHA SDK`}</h2>
    <p>{`Please download GPL version of Sencha ExtJS library from sencha.com to learn ExtJS 4 or for open source project.`}</p>
    <h2>{`WEB SERVER`}</h2>
    <p>{`You also need to install a web server on your local machine. You may use any web server of your choice.`}</p>
    <ul>
      <li parentName="ul">{`Apache HTTP Server`}</li>
      <li parentName="ul">{`IIS`}</li>
    </ul>
    <h2>{`IDE`}</h2>
    <p>{`You can use any IDE for Sencha development as per your convenient. Here are some popular IDEs:`}</p>
    <ul>
      <li parentName="ul">{`JetBrains Webstorm`}</li>
      <li parentName="ul">{`Visual Studio`}</li>
      <li parentName="ul">{`Sublime Text`}</li>
      <li parentName="ul">{`Aptana Sutdio`}</li>
      <li parentName="ul">{`Eclipse`}</li>
    </ul>
    <h2>{`EXTJS FRAMEWORK`}</h2>
    <p>{`Now let's look at what makes ExtJS so powerful, the ExtJS Framework. It comprises of many basic features such as Ajax communication, DOM manipulation, and event management. Everything else is dependent on the core of the framework, but the core isn’t dependent on anything else.`}</p>
    <h2>{`THE COMPONENT MODEL`}</h2>
    <p>{`The Component model dictates how UI widgets are instantiated, rendered, and destroyed in what’s known as the component life cycle.`}</p>
    <h2>{`The Container model`}</h2>
    <p>{`The Container model controls how widgets can manage (or contain ) other child widgets.`}</p>
    <h2>{`Ext JS core`}</h2>
    <p>{`Comprises many basic features such as Ajax communication, DOM manipulation, and event management. Everything else is dependent on the core of the framework, but the core isn’t dependent on anything else.`}</p>
    <h2>{`Containers`}</h2>
    <p>{`Containers are widgets that can manage one or more child items. eg:- Tab panel`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7500/15447003454_423c11cf67_n.jpg",
        "alt": "Containers"
      }}></img></p>
    <h2>{`Layouts`}</h2>
    <p>{`Layouts are implemented by a container to visually organize the child items in the container’s content body.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7558/15447011874_38687c48b3_n.jpg",
        "alt": "Layouts"
      }}></img></p>
    <p>{`Layouts are broken up into two main areas of functionality: component and container layouts.`}</p>
    <p><strong parentName="p">{`Component Layouts`}</strong>{` are responsible for arranging the HTML for components. `}<strong parentName="p">{`Container layouts`}</strong>{` are responsible for managing the location and size of child components.`}</p>
    <h2>{`DATA PACKAGE`}</h2>
    <p>{`The Ext JS 4.0 data package incorporates an explosion of classes, and it includes new members such as the `}<strong parentName="p">{`LocalStorage proxy`}</strong>{` and `}<strong parentName="p">{`tree store`}</strong>{`.
The LocalStorage proxy allows data to be stored and retrieved using the browser’s local storage feature, whereas the tree store replaces the Ext JS 3.0 tree loader, allowing you to do a lot more with trees than ever before.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7573/15883231109_60ac865f7e_n.jpg",
        "alt": "Data package"
      }}></img></p>
    <p>{`In Ext JS 4.0, models can directly use proxies.`}</p>
    <h2>{`NEW DOCKING SYSTEM`}</h2>
    <p>{`Panels in Ext JS can have widgets such as toolbars arranged on the outside of the area known as the content body with the component layout known as Dock.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm9.staticflickr.com/8675/15447035404_de42585cd0_n.jpg",
        "alt": "Docking system"
      }}></img></p>
    <h2>{`Grid panel improvements`}</h2>
    <p>{`New feature `}<strong parentName="p">{`infinite grid`}</strong>{`, allows you to paginate through large datasets without having to include a paging toolbar. Following figure shows the Grid panel's areas of functionality.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7517/15882068510_18ddc76fbf_n.jpg",
        "alt": "Grid panel"
      }}></img></p>
    <p>{`This means that you have more flexibility in configuring grid panels, allowing Ext JS to implement only code that’s required. For instance, if you want to allow cell editing, you include the CellEditing plug-in in your grid panel configuration. Likewise, if you want to include drag-and-drop functionality, include the DragDrop plug-in.`}</p>
    <h2>{`Tree panel now closer to grids`}</h2>
    <p>{`The grid and tree panels are siblings because they share the same superclass.
Having the grid and tree panels share the same base code means that you can have things like columns in your tree views.`}</p>
    <p><img parentName="p" {...{
        "src": "https://farm8.staticflickr.com/7513/15447060734_97b617cd9b_s.jpg",
        "alt": "Tree panel"
      }}></img></p>
    <p>{`That said, the tree panel doesn’t contain a lot of the functionality that the grid panel sports, such as the summary row plug-in or column locking. Besides, the tree panel must use the TreeStore class from the data package to manage and display hierarchical data.`}</p>
    <h2>{`Bundled packaging tool`}</h2>
    <p>{`Ext JS 4.0 comes with a dynamic class-loading system. The class loader is a great solution for internet-based Ext JS applications.`}</p>
    <p>{`Sencha now includes its popular JSBuilder tool.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      